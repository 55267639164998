import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/features/userSlice';

export default function LogoutPage() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  return (<Redirect to='/' />)
}
