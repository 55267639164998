import {
  createSlice, createAsyncThunk
} from '@reduxjs/toolkit'

import MatchService from '../../services/MatchService'

export const recent = createAsyncThunk(
  'match/recent',
  async(limit, thunkAPI) => {
    try {
      const response = await MatchService.Recent(limit)
      if (response.status === 200) return response.data
      else return thunkAPI.rejectWithValue(response)
    } catch (e) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const recentMatchesSlice = createSlice({
  name: 'recentMatches',
  initialState: {
    matches: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [recent.fulfilled]: (state, { payload }) => {
      state.matches = payload.data;
    },
    [recent.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      //state.errorMessage = payload.message;
    },
    [recent.pending]: (state) => {
      state.isFetching = true;
    },
  }
})

export const { clearState } = recentMatchesSlice.actions;

export const recentMatchesSelector = (state) => state.recentMatches;