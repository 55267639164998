import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Link } from 'react-router-dom'

const ProfileMenu = () => {
  const profilesMenu = [
    {
      text: 'Thông tin tài khoản',
      to: '/my-profile',
      selected: window.location.pathname === '/my-profile',
    },
    {
      text: 'Ví của tôi',
      to: '/my-wallet',
      selected: window.location.pathname === '/my-wallet',
    },
    {
      text: 'Lịch sử giao dich',
      to: '/transaction-history',
      selected: window.location.pathname === '/transaction-history',
    },
    {
      text: 'Lịch sử đặt kèo',
      to: '/betslip-history',
      selected: window.location.pathname === '/betslip-history',
    },
  ]

  return (
    <>
      <nav aria-label="main mailbox folders">
        <List>
          {profilesMenu.map((menu, i) => {
            return (
              <ListItem key={i} selected={menu.selected} disablePadding>
                <ListItemButton component={Link} to={menu.to}>
                  <ListItemText primary={menu.text} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </nav>
    </>
  )
}

export default ProfileMenu