import {
  createSlice, createAsyncThunk
} from '@reduxjs/toolkit'

import LeagueService from '../../services/LeagueService'

export const activeLeagues = createAsyncThunk(
  'league/activeLeagues',
  async(thunkAPI) => {
    try {
      const response = await LeagueService.Active()
      if (response.status === 200) return response.data
      else return thunkAPI.rejectWithValue(response)
    } catch (e) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const activeLeaguesSlice = createSlice({
  name: 'activeLeagues',
  initialState: {
    leagues: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [activeLeagues.fulfilled]: (state, { payload }) => {
      state.leagues = payload.data;
    },
    [activeLeagues.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      //state.errorMessage = payload.message;
    },
    [activeLeagues.pending]: (state) => {
      state.isFetching = true;
    },
  }
})

export const { clearState } = activeLeaguesSlice.actions;

export const activeLeaguesSelector = (state) => state.activeLeagues;