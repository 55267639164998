import './style.css'

export default function LiveIndicator() {
  return (
    <div className="live-indicator-block">
      <span className="live-indicator">
        <i className="blink">●</i> Live
      </span>
    </div>
  )
}