import React from 'react'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

export default function ActiveLeaguesListItem(props) {
  return (
    <ListItem disablePadding>
      <ListItemButton dense>
        <ListItemIcon>
          <img
            src={props.league.iconUrl}
            alt={props.league.name}
            width={20}
            height={20}
          />
        </ListItemIcon>
        <ListItemText primary={props.league.name} />
        <Typography>{props.league.totalMatches}</Typography>
      </ListItemButton>
    </ListItem>
  )
}
