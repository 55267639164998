import dayjs from 'dayjs'
import LiveIndicator from '../LiveIndicator'
import { Typography } from '@mui/material'
const MatchTimeBox = (props) => {
  const matchStart = dayjs(props.match.startAt)
  if (props.match.isLive) return <LiveIndicator />
  else
    return (
      <Typography sx={{ textTransform: 'uppercase' }}>
        {matchStart.format('hh:mm')}
        <br />
        {matchStart.format('DD/MM')}
      </Typography>
    )
}
export default MatchTimeBox
