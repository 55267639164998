import React from "react";
import Clear from "@mui/icons-material/Clear";
import { Box, Typography, TextField, Divider, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { setMarketOptionAmount, removeMarketOption } from "../../redux/features/betslipSlice";
import { useDispatch } from "react-redux";

const BetSlipItem = (props) => {
  const option = props.option;
  const dispatch = useDispatch();
  const onAmountChange = (evt) => {
    let amount = Number.parseFloat(evt.target.value);
    const payload = {
      marketOptionId: option.id,
      amount: amount >= 0 ? amount : 0,
    }
    dispatch(setMarketOptionAmount(payload));
  }

  const removeOptionHandler = () => {
    dispatch(removeMarketOption({id: option.id}))
  }
  
  return (
    <Box
      sx={{
        backgroundColor: 'constants.white',
        padding: "0.5rem ",
        marginBottom: "1rem",
        borderRadius: '4px',
        color: 'constants.black'
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "0.2rem",
        }}
      >
        <Typography sx={{ fontSize: "0.8rem" }}>{option.match.name}</Typography>
        <Typography sx={{ fontSize: "0.8rem" }}>
          {dayjs(option.match.startAt).format("DD MMM - HH:mm")}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.3rem",
        }}
      >
        <Box
          sx={{
            height: "30px",
            width: "50px",
            backgroundColor: 'primary.dark',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
          }}
        >
          <Typography
            sx={{ color: 'constants.white', fontWeight: "bold", fontSize: "1rem" }}
          >
            {option.odd}
          </Typography>
        </Box>
        <Box sx={{ width: "180px", padding: "0 5px" }}>
          <Typography sx={{ fontSize: "0.8rem" }}>{option.title}</Typography>
        </Box>
        <Box sx={{ width: "90px" }}>
          <TextField
            onChange={onAmountChange}
            placeholder="Cược"
            variant="outlined"
            size="small"
            defaultValue={option.amount <= 0 ? "" : option.amount}
            inputProps={{ style: { color: '#000', border: 'solid 1px #e3e3e3', borderRadius: '4px' } }} 
          />
        </Box>
        <Box>
          <IconButton onClick={removeOptionHandler} aria-label="delete" color="primary">
            <Clear />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BetSlipItem;
