import {
  createSlice, createAsyncThunk
} from '@reduxjs/toolkit'

import SportService from '../../services/SportService'

export const activeSports = createAsyncThunk(
  'sport/activeSports',
  async(thunkAPI) => {
    try {
      const response = await SportService.Active()
      if (response.status === 200) return response.data
      else return thunkAPI.rejectWithValue(response)
    } catch (e) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const activeSportsSlice = createSlice({
  name: 'activeSports',
  initialState: {
    sports: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [activeSports.fulfilled]: (state, { payload }) => {
      state.sports = payload.data;
    },
    [activeSports.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      if (payload.errors && payload.errors.length > 0) state.errorMessage = payload.errors[0]
    },
    [activeSports.pending]: (state) => {
      state.isFetching = true;
    },
  }
})

export const { clearState } = activeSportsSlice.actions;

export const activeSportsSelector = (state) => state.activeSports;