import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import LeftSidebar from '../../components/LeftSidebar'
import RightSidebar from '../../components/RightSidebar'
import './index.css'
import { useHistory } from 'react-router-dom'
import MatchDetails from '../../components/MatchDetails'

const MatchDetailsPage = (props) => {
  const history = useHistory()
  if (!props.match || !props.match.params)
    history.push({
      pathname: '/',
    })
  var matchId = props.match.params.id

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Chi tiết trận đấu | Zest.win</title>
        <meta name="description" content="Xổ số thể thao với Zest.win" />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <LeftSidebar />
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <MatchDetails id={matchId} />
        </Grid>
        <Grid item md={3}>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <RightSidebar />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default MatchDetailsPage
