import React from 'react'
import Box from '@mui/material/Box'
import { Typography, Grid } from '@mui/material'
import MatchTimeBox from '../MatchTimeBox'

const MatchDetailsHeader = (props) => {
  const match = props.match
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: '2em 0',
          width: '100%',
          background:
            'linear-gradient(345.6deg, rgba(30, 33, 41, 0.95) 10.84%, rgba(33, 43, 65, 0.99) 106.56%)',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: '250px'
        }}
      >
        <img
          src={match.league.iconUrl}
          alt={match.league.name}
          style={{ maxWidth: '30px', maxHeight: '30px', display: 'block' }}
        />
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontWeight: '300',
            fontSize: '0.9rem',
            textTransform: 'uppercase',
            margin: '0.5rem auto',
          }}
        >
          {match.league.name}
        </Typography>

        <Grid container spacing={2}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              padding: '2em 0',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontWeight: '500',
                fontSize: '1.1rem',
              }}
            >
              {match.contestants[0].name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              padding: '2em 0',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <MatchTimeBox match={match} />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              padding: '2em 0',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                fontWeight: '500',
                fontSize: '1.1rem',
              }}
            >
              {match.contestants[1].name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default MatchDetailsHeader
