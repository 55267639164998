import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
const LeftSidebar = React.lazy(() => import('../../components/LeftSidebar'))
const RightSidebar = React.lazy(() => import('../../components/RightSidebar'))
const RecentMatches = React.lazy(() => import('../../components/RecentMatches'))

const HomePage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trang chủ | Zest.win</title>
        <meta name="description" content="Xổ số thể thao với Zest.win" />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Suspense fallback={<div>Loading...</div>}>
              <LeftSidebar />
            </Suspense>
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Box>
            <Suspense fallback={<div>Loading...</div>}>
              <RecentMatches />
            </Suspense>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Suspense fallback={<div>Loading...</div>}>
              <RightSidebar />
            </Suspense>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default HomePage
