import http from '../http'

export default class TransactionService {
  static DepositHistory = async ({ pageIndex = 0, limit = 10 }) => {
    return await http().get('User/DepositHistory', {
      params: {
        page: pageIndex,
        limit: limit,
      },
    })
  }
}
