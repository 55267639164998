import { Grid, Typography } from '@mui/material'

const MarketTitle = (props) => {
  const market = props.market

  return (
    <Grid container>
      <Grid item xs={12} sx={{ backgroundColor: '#30343c', padding: '0.5rem' }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: '600',
            fontSize: '1rem'
          }}
        >
          {market.title}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MarketTitle
