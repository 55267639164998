import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import {
  activeSportsSelector,
  activeSports,
} from '../../redux/features/activeSportSlice'
import SportListItem from './SportListItem'
import Typography from '@mui/material/Typography'

export default function SportList() {
  const dispatch = useDispatch()
  const { sports } = useSelector(activeSportsSelector)

  useEffect(() => {
    dispatch(activeSports())
  }, [dispatch])

  if (sports !== null && sports.length > 0) {
    return (
      <Box sx={{marginBottom: '0.8rem'}}>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontSize: '1.3rem',
            color: 'constants.orange',
            fontWeight: 'bold',
            paddingBottom: 0,
            borderBottom: 'solid 1px',
            padding: 'inherit',
            margin: '0 1rem',
          }}
        >
          Môn thi đấu
        </Typography>
        <List>
          {sports.map((value, index) => {
            return <SportListItem key={index} sport={value} />
          })}
        </List>
      </Box>
    )
  }

  return (
    <Box>
      <List></List>
    </Box>
  )
}
