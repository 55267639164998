import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import BetService from "../../services/BetService";

export const placeBet = createAsyncThunk(
  "betslip/placeBetslip",
  async (params, thunkAPI) => {
    const stt = thunkAPI.getState();
    const options = stt.betslip.marketOptions;
    var totalAmount = 0;
    // build betslip request
    var payload = {
      options: [],
      odd: 0, // not needed on single bet
      amount: 0, // not needed on single bet
      currencyUnit: "ETH", // fixed
      type: 10, //single bet
    };
    for (let i = 0; i < options.length; i++) {
      let marketOption = {
        marketOptionId: options[i].id,
        odd: options[i].odd,
        amount: options[i].amount,
      };
      totalAmount += options[i].amount
      payload.options.push(marketOption)
    }
    payload.amount = totalAmount
    try {
      thunkAPI.dispatch(startFetching())
      const response = await BetService.PlaceBet(payload);
      thunkAPI.dispatch(stopFetching())
      if (response.status === 200) return response.data;
      else return thunkAPI.rejectWithValue(response);
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const betslipSlice = createSlice({
  name: "betslip",
  initialState: {
    marketOptions: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errors: null,
  },
  reducers: {
    clearState: (state) => {
      state.errors = null;
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = false;
      state.marketOptions = [];

      return state;
    },
    startFetching: (state) => {
      state.isFetching = true;
      return state;
    },
    stopFetching: (state) => {
      state.isFetching = false;
      return state;
    },
    setMarketOptionAmount: (state, action) => {
      const { amount, marketOptionId } = action.payload;
      for (let i = 0; i < state.marketOptions.length; i++) {
        if (state.marketOptions[i].id === marketOptionId) {
          state.marketOptions[i].amount = amount;
          break;
        }
      }
      return state;
    },
    addMarketOption: (state, action) => {
      var checkOption = state.marketOptions.filter((x) => {
        return (
          x.id === action.payload.id || x.marketId === action.payload.marketId
        );
      });
      if (checkOption === null || checkOption.length === 0)
        state.marketOptions.push(action.payload);
      return state;
    },
    clearBetslip: (state) => {
      state.marketOptions = [];
      return state;
    },
    removeMarketOption: (state, action) => {
      let index = state.marketOptions.findIndex(x => {
        return x.id === action.payload.id
      })
      if (index >= 0) state.marketOptions.splice(index, 1)
      return state
    }
  },
  extraReducers: {
    [placeBet.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      return state;
    },
    [placeBet.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      //state.errors = payload.errors
      //state.errorMessage = payload.message;
    },
    [placeBet.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const {
  clearState,
  clearBetslip,
  addMarketOption,
  setMarketOptionAmount,
  startFetching,
  stopFetching,
  removeMarketOption
} = betslipSlice.actions;

export const betslipSliceSelector = (state) => state.betslip;
