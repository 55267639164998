import React, { useEffect } from 'react'
import {
  Grid,
  Box,
  Button,
  Paper,
  TextField,
  Alert,
  Snackbar,
} from '@mui/material'
import { userSelector, balance as updateBalance } from '../../redux/features/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import UserService from '../../services/UserService'

var since = ''
let isFetching = false
var total = 0

async function delay(ms) {
  // return await for better async stack trace support in case of errors.
  return await new Promise((resolve) => setTimeout(resolve, ms))
}

const MyWallet = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const { balance } = useSelector(userSelector)
  const onAddressFocus = (evt) => evt.target.select()

  async function checkDeposit() {
    if (isFetching) return
    if (open) return
    if (window.location.pathname !== '/my-wallet') return
    isFetching = true
    try {
      let resp = await UserService.DepositSince(since)
      isFetching = false
      if (resp) {
        total = 0
        let data = resp.data.data
        since = data.since
        if (data.transactions && data.transactions.length > 0) {
          data.transactions.forEach((trx) => {
            total += trx.amount
          })
          setOpen(true)
          dispatch(updateBalance())
        }
      }
    } catch (e) {
      isFetching = false
    }
    await delay(5000)
    checkDeposit()
  }

  useEffect(() => {
    checkDeposit()
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Bạn vừa nạp thành công {total} vào ví!
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Box sx={{ m: 1, paddingLeft: 2 }}>
          <h2>Số dư</h2>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            elevation={6}
            sx={{ marginBottom: '2rem', fontSize: '2rem', padding: '3px 10px' }}
          >
            {balance.amount} {balance.currencyUnit}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            elevation={6}
            sx={{ marginBottom: '2rem', padding: '3px 10px' }}
          >
            <p>
              Để nạp tiền vào tài khoản, vui lòng chuyển {balance.currencyUnit}{' '}
              vào địa chỉ sau:
            </p>
            <TextField
              fullWidth
              aria-readonly={true}
              id="outlined-basic"
              label="Địa chỉ"
              variant="outlined"
              onFocus={onAddressFocus}
              value={balance.wallet.address}
              sx={{ marginBottom: 2, marginTop: 1 }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Box sx={{ m: 1, paddingLeft: 2 }}>
          <Button variant="outlined">Rút số dư</Button>
        </Box>
      </Grid>
    </>
  )
}

export default MyWallet
