import axios from 'axios'

const http = () => {
  const headers = {};
  if (localStorage.getItem('token')) headers.Authorization = "Bearer " + localStorage.getItem('token');
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 3000,
    headers: headers
  });

  client.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 403) {
      console.log(error)
    }
    return Promise.reject(error)
  });
  return client;
}
export default http;