import http from "../http"

export default class UserService
{
  static Login = async(username, password) => {
    const data = {username: username, password: password}
    return await http().post('Auth/Login', data)
  }

  static Signup = async(username, email, password) => {
    const data = {username: username, email: email, password: password}
    return await http().post('Auth/Register', data)
  }

  static Info = async() => {
    return await http().get('User/Info')
  }

  static Balance = async() => {
    return await http().get('User/Balance')
  }

  static DepositSince = async(since = '') => {
    if (since === '') return await http().get('User/DepositSince')
    else return await http().get('User/DepositSince?since=' + since)
  }
}