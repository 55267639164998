import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'
const MainNavbar = React.lazy(() => import('../../components/MainNavbar'))
const Footer = React.lazy(() => import('../../components/Footer'))
export const MainLayout = ({ children, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Suspense fallback={<div>Loading...</div>}>
          <MainNavbar />
        </Suspense>
        {children}
        <Suspense fallback={<div>Loading...</div>}>
          <Footer />
        </Suspense>
      </main>
    </ThemeProvider>
  )
}

const MainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <MainLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </MainLayout>
  )
}

export default MainLayoutRoute
