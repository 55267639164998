import React from 'react'
import { Grid, Box, Typography } from '@mui/material'
import MarketOptionButton from '../MarketOptionButton'
import MarketTitle from './MarketTitle'

const TopTitles = (props) => {
  const market = props.market
  const match = props.match
  const optionRowWidth = 12 / market.options[0].length

  return (
    <Box>
      <MarketTitle market={market} />
      <Grid container spacing={1}>
        {market.options[0].map((option) => {
          return (
            <Grid key={`${option.id}`} item xs={optionRowWidth}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '0.7rem',
                  textAlign: 'center',
                  display: 'block',
                  color: 'constants.light_gray'
                }}
                variant="div"
              >
                {option.title}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
      <Grid container spacing={0.5}>
        {market.options[0].map((option) => {
          return (
            <Grid key={`${option.id}`} item xs={optionRowWidth}>
              <MarketOptionButton match={match} option={option} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default TopTitles
