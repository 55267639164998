import React, { useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import BetSlipItem from './BetSlipItem'
import { useSelector, useDispatch } from 'react-redux'
import {
  betslipSliceSelector,
  clearState,
  clearBetslip,
  placeBet,
} from '../../redux/features/betslipSlice'
import { LoadingButton } from '@mui/lab'
import { Link } from '@mui/material'
import { balance } from '../../redux/features/userSlice'
import { useSnackbar } from 'notistack'

const BetSlipBox = () => {
  const dispatch = useDispatch()
  const { marketOptions, isSuccess, isFetching } =
    useSelector(betslipSliceSelector)
  const { enqueueSnackbar } = useSnackbar()

  const isLoggedIn =
    localStorage.getItem('token') !== null &&
    localStorage.getItem('token').length > 0

  const clearHandler = (evt) => {
    dispatch(clearBetslip())
  }

  const placeBetslipHandler = (evt) => {
    dispatch(placeBet())
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Đặt cược thành công!')
      dispatch(balance())
      dispatch(clearState())
    }
  }, [isSuccess, dispatch, enqueueSnackbar])

  const BetSlipBoxContent = () => {
    if (marketOptions !== null && marketOptions.length > 0) {
      return (
        <React.Fragment>
          {marketOptions.map((value, index) => {
            return <BetSlipItem option={value} key={index} />
          })}
        </React.Fragment>
      )
    } else {
      return <React.Fragment />
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ padding: '1rem' }}>
        <Box sx={{ borderBottom: '1px solid #333', marginBottom: '1rem' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Phiếu cược của bạn
          </Typography>
        </Box>
        <Box>
          <BetSlipBoxContent />
        </Box>
        <Box>
          {isLoggedIn ? (
            <LoadingButton
              loading={isFetching}
              onClick={placeBetslipHandler}
              variant="primary"
              style={{ width: '100%', marginBottom: '5px' }}
            >
              Đặt cược
            </LoadingButton>
          ) : (
            <Button
              component={Link}
              href="/login"
              variant="primary"
              style={{ width: '100%', marginBottom: '5px' }}
            >
              Đăng nhập để đặt cược
            </Button>
          )}
          <Button
            onClick={clearHandler}
            variant="secondary"
            color="error"
            style={{ width: '100%' }}
            disabled={!isLoggedIn}
          >
            Xóa cược
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default BetSlipBox
