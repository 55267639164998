import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { betHistorySelector, betHistory } from '../../redux/features/betHistorySlice'
import { Box } from '@mui/system'
import { Grid, Paper, Divider } from '@mui/material'

const ProfileHistoryBet = () => {
  const { bets } = useSelector(betHistorySelector)
  const dispatch = useDispatch()
  console.log(bets)

  useEffect(() => {
    dispatch(betHistory())
  }, [dispatch])
  
  return (
    <>
      <Box component={Paper} elevation={3}>
        <Grid container spacing={2}>
          <Grid item md={6}>Ngày giờ</Grid>
          <Grid item md={6}>Mã betslip</Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item md={8}>Tên trấn đấu</Grid>
          <Grid item md={4}>Ngày giờ bắt đầu</Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={8}>Tên kèo</Grid>
          <Grid item md={4}></Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={8}>Option chọn</Grid>
          <Grid item md={4}>Tỉ lệ</Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={8}>Kết quả (thắng/thua)</Grid>
          <Grid item md={4}>Tỉ lệ cược</Grid>
        </Grid>
        <Divider />
      </Box>
    </>
  )
}

export default ProfileHistoryBet
