import http from '../http'

export default class MatchService {
  static Recent = async (limit = 10) => {
    return await http().get('Match/Recent', { params: { limit: limit } })
  }

  static Detail = async (id) => {
    return await http().get('Match', { params: { id: id } })
  }
}
