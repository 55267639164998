import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Link } from 'react-router-dom'

export default function SportListItem(props) {
  var url = '/sport/' + props.sport.id
  return (
    <ListItem disablePadding>
      <ListItemButton dense component={Link} to={url}>
        <ListItemIcon>
          <img src={props.sport.iconUrl} alt={props.sport.name} width={20} height={20} />
        </ListItemIcon>
        <ListItemText primary={props.sport.name} />
      </ListItemButton>
    </ListItem>
  )
}