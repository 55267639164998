import React from 'react'
import { Grid } from '@mui/material'
import ProfileMenu from '../../components/ProfileMenu'
import MyInfo from '../../components/MyInfo'
import { Helmet } from 'react-helmet-async'

const MyProfilePage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thông tin cá nhân | Zest.win</title>
      </Helmet>
      <Grid container spacing={2} sx={{ maxWidth: '900px', margin: 'auto' }}>
        <Grid item md={3}>
          <ProfileMenu />
        </Grid>
        <Grid item md={9}>
          <MyInfo />
        </Grid>
      </Grid>
    </>
  )
}

export default MyProfilePage
