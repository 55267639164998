import React, { useEffect } from 'react'
import {
  matchDetailSelector,
  detail,
} from '../../redux/features/matchDetailSlice'
import { useSelector, useDispatch } from 'react-redux'
import MatchDetailsHeader from './header'
import TopTitles from '../MarketOptions/TopTitles'

const MatchDetails = (props) => {
  const matchId = props.id
  const dispatch = useDispatch()

  const { isSuccess, match, isFetching } = useSelector(matchDetailSelector)

  useEffect(() => {
    dispatch(detail(matchId))
  }, [dispatch, matchId])
  if (isSuccess && !isFetching) {
    return (
      <>
        <MatchDetailsHeader match={match} />
        {match.markets.map((market) => {
          switch (market.type) {
            case 'MONEYLINE':
              return <TopTitles match={match} market={market} />
            default:
              return (<></>)
          }
        })}
      </>
    )
  }

  return <></>
}

export default MatchDetails
