import React from 'react'
import { Grid } from '@mui/material'
import ProfileMenu from '../../components/ProfileMenu'
import MyWallet from '../../components/MyWallet'
import { Helmet } from 'react-helmet-async'

const MyWalletPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Số dư tài khoản | Zest.win</title>
      </Helmet>
      <Grid container spacing={2} sx={{ maxWidth: '900px', margin: 'auto' }}>
        <Grid item md={3}>
          <ProfileMenu />
        </Grid>
        <Grid item md={9}>
          <MyWallet />
        </Grid>
      </Grid>
    </>
  )
}

export default MyWalletPage
