import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// PAGES
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import SignUpPage from './pages/SignUpPage'
import ErrorPage from './pages/ErrorPage'
import LogoutPage from './pages/LogoutPage'
import MyProfilePage from './pages/MyProfilePage'
import MyWalletPage from './pages/MyWalletPage'
import TransactionHistoryPage from './pages/TransactionHistoryPage'
import BetslipHistoryPage from './pages/BetslipHistoryPage'
import MatchDetailsPage from './pages/MatchDetailsPage'

import MainLayoutRoute from './layouts/MainLayout'

function App() {
  return (
    <Router>
      <Switch>
        <MainLayoutRoute exact path='/' component={HomePage} />
        <MainLayoutRoute exact path='/login' component={LoginPage} />
        <MainLayoutRoute exact path='/logout' component={LogoutPage} />
        <MainLayoutRoute exact path='/signup' component={SignUpPage} />
        <MainLayoutRoute path='/my-profile' component={MyProfilePage} />
        <MainLayoutRoute path='/my-wallet' component={MyWalletPage} />
        <MainLayoutRoute path='/transaction-history' component={TransactionHistoryPage} />
        <MainLayoutRoute path='/betslip-history' component={BetslipHistoryPage} />
        <MainLayoutRoute exact path='/match/:id' component={MatchDetailsPage} />
        <Route exact path='*' component={ErrorPage} />
      </Switch>
    </Router>
  )
}

export default App;
