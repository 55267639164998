import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import BetService from '../../services/BetService'

export const betHistory = createAsyncThunk(
  'bet/history',
  async (params, thunkAPI) => {
    const defaultParams = { pageIndex: 0, limit: 10 }
    const args = {...defaultParams, params}
    try {
      const response = await BetService.History(args)
      if (response.status === 200) return response.data
      else return thunkAPI.rejectWithValue(response)
    } catch (e) {
      console.log('Error', e.response.data)
      thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const betHistorySlice = createSlice({
  name: 'betHistory',
  initialState: {
    bets: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.bets = []
      state.isError = false
      state.isSuccess = false
      state.isFetching = false

      return state
    },
  },
  extraReducers: {
    [betHistory.fulfilled]: (state, { payload }) => {
      state.bets = payload.data
    },
    [betHistory.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      //state.errorMessage = payload.message;
    },
    [betHistory.pending]: (state) => {
      state.isFetching = true
    },
  },
})

export const { clearState } = betHistorySlice.actions

export const betHistorySelector = (state) => state.betHistory
