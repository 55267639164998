import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    snacks: []
  },
  reducers: {
    clearState: (state) => {
      state.snacks = []

      return state
    },
    pullSnack: (state => {
      var snack = state.snacks.splice(0, 1)
      return snack
    }),
    pullAllSnacks: (state => {
      var snacks = Array.from(state.snacks)
      state.snacks = []
      return snacks
    }),
    addSnack: ((state, action) => {
      state.snacks.push(action.payload)
    })
  },
  extraReducers: {
  },
})

export const { clearState, pullSnack, pullAllSnacks, addSnack } = notificationSlice.actions

export const snackSelector = (state) => state.snacks
