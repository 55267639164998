import { Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { addMarketOption } from '../../redux/features/betslipSlice'
import { useDispatch } from 'react-redux'

const MarketOptionButton = (props) => {
  const option = props.option
  const match = props.match
  const dispatch = useDispatch()

  const btStyle = {
    backgroundColor: 'constants.dark_blue',
    color: 'constants.white',
    fontWeight: '300',
    display: 'block',
    width: '100%',
    '&:hover': {
      backgroundColor: 'constants.light_gray_blue',
    },
  }

  const handler = (evt) => {
    const payload = {
      match: {
        id: match.id,
        startAt: match.startAt,
        leagueName: match.league.name,
        name: `${match.contestants[0].name} vs ${match.contestants[1].name}`,
      },
      amount: 0,
      currencyUnit: 'ETH',
      ...option,
    }
    dispatch(addMarketOption(payload))
  }
  return (
    <Tooltip title={option.title} placement="top">
      <Button onClick={handler} sx={btStyle}>
        {option.odd}
      </Button>
    </Tooltip>
  )
}

export default MarketOptionButton
