import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { userSlice } from './features/userSlice'
import { recentMatchesSlice } from './features/recentMatchesSlice'
import { activeLeaguesSlice } from './features/activeLeagueSlice'
import { betslipSlice } from './features/betslipSlice'
import { transactionSlice } from './features/transactionSlice'
import { betHistorySlice } from './features/betHistorySlice'
import { activeSportsSlice } from './features/activeSportSlice'
import { persistReducer } from 'redux-persist'
import { notificationSlice } from './features/notificationSlice'
import { matchDetailSlice } from './features/matchDetailSlice'

import storage from 'redux-persist/lib/storage'
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const reducers = combineReducers({
  user: userSlice.reducer,
  recentMatches: recentMatchesSlice.reducer,
  activeLeagues: activeLeaguesSlice.reducer,
  betslip: betslipSlice.reducer,
  transaction: transactionSlice.reducer,
  betHistory: betHistorySlice.reducer,
  activeSports: activeSportsSlice.reducer,
  notification: notificationSlice.reducer,
  matchDetail: matchDetailSlice.reducer
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

// configure middleware as guided at: https://github.com/rt2zz/redux-persist/issues/988
// to suppress error
export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
  },
})
