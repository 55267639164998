import React, { useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  transactionSelector,
  depositHistory,
} from '../../redux/features/transactionSlice'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'

const ProfileTransaction = () => {
  const { transactions } = useSelector(transactionSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(depositHistory())
  }, [dispatch])

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Thời gian</TableCell>
              <TableCell align="right">Loại</TableCell>
              <TableCell align="right">Số lượng</TableCell>
              <TableCell align="right">Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {dayjs(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}
                </TableCell>
                <TableCell align="right">{row.sourceType}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                {row.status === 100 && <TableCell align="right">Hoàn thành</TableCell>}
                {row.status === 10 && <TableCell align="right">Đang chờ</TableCell>}
                {row.status === 200 && <TableCell align="right">Thất bại</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ProfileTransaction
