import http from "../http"

export default class BetService
{
  static PlaceBet = async(betslip) => {
    return await http().post('Bet', betslip)
  }

  static History = async() => {
    return await http().get('Bet/History')
  }
}