import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector, login, logout } from '../../redux/features/userSlice'
import { useSnackbar } from 'notistack'
import theme from '../../theme'

import { Helmet } from 'react-helmet-async'

export default function LoginPage() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  const onSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    let username = data.get('username')
    let password = data.get('password')
    dispatch(login({ username: username, password: password }))
  }

  useEffect(() => {
    if (isSuccess && localStorage.getItem('token')) {
      enqueueSnackbar('Đăng nhập thành công. Bạn sẽ được chuyển về trang chủ sau 2 giây.', {
        preventDuplicate: true,
      })
      setTimeout(() => {
        history.push({
          pathname: '/',
        })
      }, 2000)
    }
  }, [isSuccess, enqueueSnackbar, history])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(errorMessage, {
        preventDuplicate: true,
      })
    }
  }, [isError, errorMessage, enqueueSnackbar])

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Đăng nhập | Zest.win</title>
        <meta name="description" content="Xổ số thể thao với Zest.win" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Đăng nhập
          </Typography>
          <form onSubmit={onSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Tên đăng nhập"
              name="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid
              container
              style={{
                display: 'flex',
                alignItem: 'baseline',
                justifyContent: 'center',
              }}
            >
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Lưu mật khẩu"
                />
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {'Quên mật khẩu'}
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              disabled={isFetching}
            >
              Đăng nhập
            </Button>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
