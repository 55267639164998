import { createTheme } from '@mui/material/styles'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const ORANGE = '#F37335'
const GRAY_BRIGHT = '#4C596A'
const WHITE = '#ffffff'
const BLACK = '#000000'
const DARK_BLUE = '#2B303F'
const LIGHT_GRAY_BLUE = '#555965'
const LIGHT_GRAY = '#858585'
export default createTheme({
  palette: {
    mode: 'dark',
    constants: {
      white: WHITE,
      gray_bright: GRAY_BRIGHT,
      orange: ORANGE,
      black: BLACK,
      dark_blue: DARK_BLUE,
      light_gray_blue: LIGHT_GRAY_BLUE,
      light_gray: LIGHT_GRAY
    },
    primary: {
      main: ORANGE,
      slipbox: WHITE
    },
    background: {
      default: '#1E2327',
    }
  },
  typography: {
    body1: {
      fontSize: '0.85rem',
    },
    fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold'
        },
        containedPrimary: {
          backgroundColor: ORANGE,
          color: WHITE,
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: ORANGE
          },
        },{
          props: { variant: 'secondary' },
          style: {
            backgroundColor: GRAY_BRIGHT
          },
        },
      ],
    },
  },
})
