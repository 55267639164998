import * as React from 'react'

import ActiveLeaguesList from './ActiveLeaguesList'
import SportList from './SportList'

export default function LeftSidebar() {
  return (
    <React.Fragment>
      <ActiveLeaguesList />
      <SportList />
    </React.Fragment>
  )
}
