import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserService from '../../services/UserService'

export const login = createAsyncThunk(
  'user/login',
  async ({ username, password }, thunkAPI) => {
    try {
      const response = await UserService.Login(username, password)
      if (response.status === 200) {
        return response.data
      } else {
        return thunkAPI.rejectWithValue(response.data)
      }
    } catch (e) {
      console.log('Error', e.response.data)
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const signup = createAsyncThunk(
  'user/signup',
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await UserService.Signup(username, email, password)
      if (response.status === 200) {
        localStorage.setItem('token', response.data.data.token)
        return response.data
      } else {
        return thunkAPI.rejectWithValue(response.data)
      }
    } catch (e) {
      console.log('Error', e.response.data)
      thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const balance = createAsyncThunk('user/balance', async (thunkAPI) => {
  try {
    const response = await UserService.Balance()
    if (response.status === 200) return response.data
    else return thunkAPI.rejectWithValue(response)
  } catch (e) {
    localStorage.removeItem('token')
    console.log('Error', e.response.data)
    thunkAPI.rejectWithValue(e.response.data)
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: '',
    userId: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    balance: null,
    isStaleBalance: false, // trigger this to true will update balance
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false
      state.isStaleBalance = false

      return state
    },
    logout: (state) => {
      state.username = ''
      state.userId = ''
      state.balance = null
      state.isStaleBalance = false
      state.isError = false
      state.isSuccess = false
      state.isFetching = false
      state.errorMessage = ''
      localStorage.removeItem('token')

      return state
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      localStorage.setItem('token', payload.data.token)
      state.username = payload.data.userName
      state.userId = payload.data.userId
      state.balance = payload.data.balance
      state.isFetching = false
      state.isSuccess = true
      return state
    },
    [login.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      if (payload.errors && payload.errors.length > 0) state.errorMessage = payload.errors[0]
    },
    [login.pending]: (state) => {
      state.isFetching = true
    },
    [balance.fulfilled]: (state, { payload }) => {
      state.balance = payload.data
    },
    [balance.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      if (payload.errors && payload.errors.length > 0) state.errorMessage = payload.errors[0]
    },
    [balance.pending]: (state) => {
      state.isFetching = true
    },
    [signup.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.isSuccess = true
      localStorage.setItem('token', payload.data.token)
      state.username = payload.data.userName
      state.userId = payload.data.userId
      state.balance = payload.data.balance
      return state
    },
    [signup.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      if (payload.errors && payload.errors.length > 0) state.errorMessage = payload.errors[0]
    },
    [signup.pending]: (state) => {
      state.isFetching = true
    },
  },
})

export const { clearState, logout } = userSlice.actions

export const userSelector = (state) => state.user
