import {
  createSlice, createAsyncThunk
} from '@reduxjs/toolkit'

import MatchService from '../../services/MatchService'

export const detail = createAsyncThunk(
  'match/detail',
  async(id, thunkAPI) => {
    try {
      const response = await MatchService.Detail(id)
      if (response.status === 200) return response.data
      else return thunkAPI.rejectWithValue(response)
    } catch (e) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const matchDetailSlice = createSlice({
  name: 'matchDetail',
  initialState: {
    match: {},
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false

      return state;
    },
  },
  extraReducers: {
    [detail.fulfilled]: (state, { payload }) => {
      state.isSuccess = true
      state.isError = false
      state.isFetching = false
      state.match = payload.data
    },
    [detail.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      state.isSuccess = false
      if (payload.errors && payload.errors.length > 0) state.errorMessage = payload.errors[0]
    },
    [detail.pending]: (state) => {
      state.isFetching = true
    },
  }
})

export const { clearState } = matchDetailSlice.actions;

export const matchDetailSelector = (state) => state.matchDetail;