import React from 'react'
import { Grid, TextField, Box, Button } from '@mui/material'
const MyInfo = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Box sx={{m: 1, paddingLeft: 2}}>
          <h2>Thông tin cá nhân</h2>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
          >
            <TextField
              id="outlined"
              label="Tên hiển thị"
              sx={{ width: '100%' }}
            />
            <TextField id="outlined" label="Họ" sx={{ width: '100%' }} />
            <TextField id="outlined" label="Tên" sx={{ width: '100%' }} />
            <TextField id="outlined" label="Email" sx={{ width: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
          >
            <TextField id="outlined" label="Quốc gia" sx={{ width: '100%' }} />
            <TextField id="outlined" label="Thành phố" sx={{ width: '100%' }} />
            <TextField id="outlined" label="Địa chỉ" sx={{ width: '100%' }} />
            <TextField
              id="outlined"
              label="Số điện thoại"
              sx={{ width: '100%' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ m: 1 }}>
            <Button variant="contained">Lưu</Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default MyInfo