import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import TransactionService from '../../services/TransactionService'

export const depositHistory = createAsyncThunk(
  'transaction/depositHistory',
  async (params, thunkAPI) => {
    const defaultParams = { pageIndex: 0, limit: 10 }
    const args = {...defaultParams, params}
    try {
      const response = await TransactionService.DepositHistory(args)
      if (response.status === 200) return response.data
      else return thunkAPI.rejectWithValue(response)
    } catch (e) {
      console.log('Error', e.response.data)
      thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    transactions: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.transactions = []
      state.isError = false
      state.isSuccess = false
      state.isFetching = false

      return state
    },
  },
  extraReducers: {
    [depositHistory.fulfilled]: (state, { payload }) => {
      state.transactions = payload.data
    },
    [depositHistory.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isError = true
      //state.errorMessage = payload.message;
    },
    [depositHistory.pending]: (state) => {
      state.isFetching = true
    },
  },
})

export const { clearState } = transactionSlice.actions

export const transactionSelector = (state) => state.transaction
